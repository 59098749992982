import { tv } from 'tailwind-variants'

import type { VariantProps } from 'tailwind-variants'

import { baseButtonStyles } from './base-button.styles'

export const buttonStyles = tv({
  extend: baseButtonStyles,
  variants: {
    variant: {
      solid: '',
      soft: '',
      surface: '',
      outline: '',
      ghost: '',
      transparent: '',
    },

    color: {
      accent: '',
      neutral: '',
      destructive: '',
    },

    size: {
      xs: '',
      sm: '',
      md: '',
      lg: '',
    },

    isIconOnly: {
      true: '',
    },

    highContrast: {
      true: '',
    },

    isDisabled: {
      true: 'select-none',
    },

    isVisuallyDisabled: {
      true: '',
    },

    isLink: {
      true: 'cursor-pointer',
    },

    isLoading: {
      true: 'opacity-80',
    },
  },
  compoundVariants: [
    /** Sizes, No Icon */
    { size: 'xs', isIconOnly: false, className: 'h-[24px] px-2 text-xs' },
    { size: 'sm', isIconOnly: false, className: 'h-[32px] min-w-[70px] px-3 text-sm' },
    { size: 'md', isIconOnly: false, className: 'h-[40px] px-4 text-sm' },
    { size: 'lg', isIconOnly: false, className: 'h-[48px] px-5 text-base' },

    /** Sizes, With Icon */
    { size: 'xs', isIconOnly: true, className: 'size-[16px] [&>svg]:size-[12px]' },
    { size: 'sm', isIconOnly: true, className: 'size-[24px] [&>svg]:size-[15px]' },
    { size: 'md', isIconOnly: true, className: 'size-[32px] [&>svg]:size-[18px]' },
    { size: 'lg', isIconOnly: true, className: 'size-[40px] [&>svg]:size-[22px]' },

    /** Solid, Accent */
    {
      variant: 'solid',
      color: 'accent',
      highContrast: false,
      className: 'bg-accent-9 hover:bg-accent-10 pressed:brightness-110 text-accent-contrast',
    },

    /** Solid, Accent, High Contrast */
    {
      variant: 'solid',
      color: 'accent',
      highContrast: true,
      className:
        'bg-accent-12 text-neutral-1 hover:bg-accent-12 pressed:brightness-100 hover:brightness-125',
    },

    /** Solid, Neutral */
    {
      variant: 'solid',
      color: 'neutral',
      highContrast: false,
      className: 'bg-neutral-9 hover:bg-neutral-10 pressed:brightness-110 text-neutral-contrast',
    },

    /** Solid, Neutral, High Contrast */
    {
      variant: 'solid',
      color: 'neutral',
      highContrast: true,
      className:
        'bg-neutral-12 text-neutral-1 hover:bg-neutral-12 pressed:brightness-100 hover:brightness-110',
    },

    /** Solid, Destructive */
    {
      variant: 'solid',
      color: 'destructive',
      highContrast: false,
      className: 'bg-red-9 hover:bg-red-10 pressed:brightness-110 text-red-contrast',
    },

    /** Solid, Destructive, High Contrast */
    {
      variant: 'solid',
      color: 'destructive',
      highContrast: true,
      className:
        'bg-red-12 text-neutral-1 hover:bg-red-12 pressed:brightness-100 hover:brightness-110',
    },

    /** Solid, Disabled */
    {
      variant: 'solid',
      isDisabled: true,
      className: 'bg-neutralA-3 text-disabled-foreground',
    },

    /** Solid, Visually Disabled */
    {
      variant: 'solid',
      isVisuallyDisabled: true,
      className: '!bg-neutralA-3 !text-disabled-foreground !brightness-100',
    },

    /** Soft, Accent */
    {
      variant: 'soft',
      color: 'accent',
      highContrast: false,
      className: 'bg-accentA-3 hover:bg-accentA-4 pressed:bg-accentA-5 text-accentA-11',
    },

    /** Soft, Accent, High Contrast */
    {
      variant: 'soft',
      color: 'accent',
      highContrast: true,
      className: 'bg-accentA-3 hover:bg-accentA-4 pressed:bg-accentA-5 text-accentA-12',
    },

    /** Soft, Neutral */
    {
      variant: 'soft',
      color: 'neutral',
      highContrast: false,
      className: 'bg-neutralA-3 hover:bg-neutralA-4 pressed:bg-neutralA-5 text-neutralA-11',
    },

    /** Soft, Neutral, High Contrast */
    {
      variant: 'soft',
      color: 'neutral',
      highContrast: true,
      className: 'bg-neutralA-3 hover:bg-neutralA-4 pressed:bg-neutralA-5 text-neutralA-12',
    },

    /** Soft, Destructive */
    {
      variant: 'soft',
      color: 'destructive',
      highContrast: false,
      className: 'bg-redA-3 hover:bg-redA-4 pressed:bg-redA-5 text-redA-11',
    },

    /** Soft, Destructive, High Contrast */
    {
      variant: 'soft',
      color: 'destructive',
      highContrast: true,
      className: 'bg-redA-3 hover:bg-redA-4 pressed:bg-redA-5 text-redA-12',
    },

    /** Soft, Disabled */
    {
      variant: 'soft',
      isDisabled: true,
      className: 'bg-neutralA-3 text-disabled-foreground',
    },

    /** Soft, Visually Disabled */
    {
      variant: 'soft',
      isVisuallyDisabled: true,
      className: '!bg-neutralA-3 !text-disabled-foreground',
    },

    /** Surface, Accent */
    {
      variant: 'surface',
      color: 'accent',
      highContrast: false,
      className:
        'bg-accentA-2 border-accentA-6 text-accentA-11 hover:border-accentA-7 pressed:bg-accentA-3',
    },

    /** Surface, Accent, High Contrast */
    {
      variant: 'surface',
      color: 'accent',
      highContrast: true,
      className:
        'bg-accentA-2 border-accentA-6 text-accentA-12 hover:border-accentA-7 pressed:bg-accentA-3',
    },

    /** Surface, Neutral */
    {
      variant: 'surface',
      color: 'neutral',
      highContrast: false,
      className:
        'bg-neutralA-2 border-neutralA-6 text-neutralA-11 hover:border-neutralA-7 pressed:bg-neutralA-3',
    },

    /** Surface, Neutral, High Contrast */
    {
      variant: 'surface',
      color: 'neutral',
      highContrast: true,
      className:
        'bg-neutralA-2 border-neutralA-6 text-neutralA-12 hover:border-neutralA-7 pressed:bg-neutralA-3',
    },

    /** Surface, Destructive */
    {
      variant: 'surface',
      color: 'destructive',
      highContrast: false,
      className: 'bg-redA-2 border-redA-6 text-redA-11 hover:border-redA-7 pressed:bg-redA-3',
    },

    /** Surface, Destructive, High Contrast */
    {
      variant: 'surface',
      color: 'destructive',
      highContrast: true,
      className: 'bg-redA-2 border-redA-6 text-redA-12 hover:border-redA-7 pressed:bg-redA-3',
    },

    /** Surface, Disabled */
    {
      variant: 'surface',
      isDisabled: true,
      className: 'bg-neutralA-2 border-neutralA-6 text-disabled-foreground',
    },

    /** Surface, Visually Disabled */
    {
      variant: 'surface',
      isVisuallyDisabled: true,
      className: '!bg-neutralA-2 !border-neutralA-6 !text-disabled-foreground',
    },

    /** Outline, Accent */
    {
      variant: 'outline',
      color: 'accent',
      highContrast: false,
      className: 'border-accentA-6 text-accentA-11 hover:bg-accentA-2 pressed:bg-accentA-3',
    },

    /** Outline, Accent, High Contrast */
    {
      variant: 'outline',
      color: 'accent',
      highContrast: true,
      className: 'border-accentA-11 text-accentA-12 hover:bg-accentA-2 pressed:bg-accentA-3',
    },

    /** Outline, Neutral */
    {
      variant: 'outline',
      color: 'neutral',
      highContrast: false,
      className: 'border-neutralA-6 text-neutralA-11 hover:bg-neutralA-2 pressed:bg-neutralA-3',
    },

    /** Outline, Neutral, High Contrast */
    {
      variant: 'outline',
      color: 'neutral',
      highContrast: true,
      className: 'border-neutralA-11 text-neutralA-12 hover:bg-neutralA-2 pressed:bg-neutralA-3',
    },

    /** Outline, Destructive */
    {
      variant: 'outline',
      color: 'destructive',
      highContrast: false,
      className: 'border-redA-6 text-redA-11 hover:bg-redA-2 pressed:bg-redA-3',
    },

    /** Outline, Destructive, High Contrast */
    {
      variant: 'outline',
      color: 'destructive',
      highContrast: true,
      className: 'border-redA-11 text-redA-12 hover:bg-redA-2 pressed:bg-redA-3',
    },

    /** Outline, Disabled */
    {
      variant: 'outline',
      isDisabled: true,
      className: 'border-neutralA-6 text-disabled-foreground',
    },

    /** Outline, Visually Disabled */
    {
      variant: 'outline',
      isVisuallyDisabled: true,
      className: 'border-neutralA-6 text-disabled-foreground !bg-neutralA-2',
    },

    /** Ghost, Accent */
    {
      variant: 'ghost',
      color: 'accent',
      highContrast: false,
      className: 'text-accentA-11 hover:bg-accentA-3 pressed:bg-accentA-4',
    },

    /** Ghost, Accent, High Contrast */
    {
      variant: 'ghost',
      color: 'accent',
      highContrast: true,
      className: 'text-accentA-12 hover:bg-accentA-3 pressed:bg-accentA-4',
    },

    /** Ghost, Neutral */
    {
      variant: 'ghost',
      color: 'neutral',
      highContrast: false,
      className: 'text-neutralA-11 hover:bg-neutralA-3 pressed:bg-neutralA-4',
    },

    /** Ghost, Neutral, High Contrast */
    {
      variant: 'ghost',
      color: 'neutral',
      highContrast: true,
      className: 'text-neutralA-12 hover:bg-neutralA-3 pressed:bg-neutralA-4',
    },

    /** Ghost, Destructive */
    {
      variant: 'ghost',
      color: 'destructive',
      highContrast: false,
      className: 'text-redA-11 hover:bg-redA-3 pressed:bg-redA-4',
    },

    /** Ghost, Destructive, High Contrast */
    {
      variant: 'ghost',
      color: 'destructive',
      highContrast: true,
      className: 'text-redA-12 hover:bg-redA-3 pressed:bg-redA-4',
    },

    /** Ghost, Disabled */
    {
      variant: 'ghost',
      isDisabled: true,
      className: 'text-disabled-foreground',
    },

    /** Ghost, Visually Disabled */
    {
      variant: 'ghost',
      isVisuallyDisabled: true,
      className: 'text-disabled-foreground !bg-transparent',
    },

    /** Transparent, Accent */
    {
      variant: 'transparent',
      color: 'accent',
      highContrast: false,
      className: 'text-accentA-11 hover:text-accentA-12',
    },

    /** Transparent, Neutral */
    {
      variant: 'transparent',
      color: 'neutral',
      highContrast: false,
      className: 'text-neutralA-11 hover:text-neutralA-12',
    },

    /** Transparent, Destructive */
    {
      variant: 'transparent',
      color: 'destructive',
      highContrast: false,
      className: 'text-redA-11 hover:text-redA-12',
    },

    /** Transparent, Disabled */
    {
      variant: 'transparent',
      isDisabled: true,
      className: 'text-disabled-foreground',
    },

    /** Transparent, Visually Disabled */
    {
      variant: 'transparent',
      isVisuallyDisabled: true,
      className: '!text-disabled-foreground',
    },

    /** Link, Disabled */
    {
      isLink: true,
      isDisabled: true,
      className: 'cursor-default',
    },

    /** Link, Visually Disabled */
    {
      isLink: true,
      isVisuallyDisabled: true,
      className: 'cursor-default',
    },
  ],
  defaultVariants: {
    size: 'md',
    variant: 'solid',
    color: 'accent',
    highContrast: false,
    isDisabled: false,
    isIconOnly: false,
    isLoading: false,
  },
})

export type ButtonVariants = VariantProps<typeof buttonStyles>
