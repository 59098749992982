import { createFileRoute } from '@tanstack/react-router'
import { useTitle } from 'ahooks'
import { CheckCircleIcon } from 'lucide-react'

import { Button } from '@fysioscout/ui/buttons/button'
import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'

export const Route = createFileRoute('/_unauth/confirm')({
  component: ConfirmView,
})

function ConfirmView() {
  useTitle('Glemt adgangskode')
  const navigate = Route.useNavigate()

  return (
    <div data-testid={'forgotten-password-view'}>
      <div className={'hstack items-center gap-3'}>
        <Heading size={'5'}>Email sendt</Heading>
        <CheckCircleIcon className={'size-6'} />
      </div>

      <Text elementType={'p'} size={'2'} muted className={'mt-2'}>
        Tjek din e-mail for at nulstille din adgangskode. Hvis du ikke modtager en e-mail, så prøv
        igen.
      </Text>

      <Button
        className={'mt-4'}
        onPress={() => void navigate({ to: '/forgotten-password' })}
        variant={'outline'}
        size={'sm'}
        color={'neutral'}
      >
        Prøv igen
      </Button>
    </div>
  )
}
