/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './../routes/__root'
import { Route as UnauthRouteImport } from './../routes/_unauth/route'
import { Route as AuthRouteImport } from './../routes/_auth/route'
import { Route as AuthIndexImport } from './../routes/_auth/index'
import { Route as UnauthOtpImport } from './../routes/_unauth/otp'
import { Route as UnauthLoginImport } from './../routes/_unauth/login'
import { Route as UnauthForgottenPasswordImport } from './../routes/_unauth/forgotten-password'
import { Route as UnauthConfirmImport } from './../routes/_unauth/confirm'
import { Route as AuthResetPasswordImport } from './../routes/_auth/reset-password'

// Create/Update Routes

const UnauthRouteRoute = UnauthRouteImport.update({
  id: '/_unauth',
  getParentRoute: () => rootRoute,
} as any)

const AuthRouteRoute = AuthRouteImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRouteRoute,
} as any)

const UnauthOtpRoute = UnauthOtpImport.update({
  id: '/otp',
  path: '/otp',
  getParentRoute: () => UnauthRouteRoute,
} as any)

const UnauthLoginRoute = UnauthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => UnauthRouteRoute,
} as any)

const UnauthForgottenPasswordRoute = UnauthForgottenPasswordImport.update({
  id: '/forgotten-password',
  path: '/forgotten-password',
  getParentRoute: () => UnauthRouteRoute,
} as any)

const UnauthConfirmRoute = UnauthConfirmImport.update({
  id: '/confirm',
  path: '/confirm',
  getParentRoute: () => UnauthRouteRoute,
} as any)

const AuthResetPasswordRoute = AuthResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => AuthRouteRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthRouteImport
      parentRoute: typeof rootRoute
    }
    '/_unauth': {
      id: '/_unauth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof UnauthRouteImport
      parentRoute: typeof rootRoute
    }
    '/_auth/reset-password': {
      id: '/_auth/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof AuthResetPasswordImport
      parentRoute: typeof AuthRouteImport
    }
    '/_unauth/confirm': {
      id: '/_unauth/confirm'
      path: '/confirm'
      fullPath: '/confirm'
      preLoaderRoute: typeof UnauthConfirmImport
      parentRoute: typeof UnauthRouteImport
    }
    '/_unauth/forgotten-password': {
      id: '/_unauth/forgotten-password'
      path: '/forgotten-password'
      fullPath: '/forgotten-password'
      preLoaderRoute: typeof UnauthForgottenPasswordImport
      parentRoute: typeof UnauthRouteImport
    }
    '/_unauth/login': {
      id: '/_unauth/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof UnauthLoginImport
      parentRoute: typeof UnauthRouteImport
    }
    '/_unauth/otp': {
      id: '/_unauth/otp'
      path: '/otp'
      fullPath: '/otp'
      preLoaderRoute: typeof UnauthOtpImport
      parentRoute: typeof UnauthRouteImport
    }
    '/_auth/': {
      id: '/_auth/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof AuthRouteImport
    }
  }
}

// Create and export the route tree

interface AuthRouteRouteChildren {
  AuthResetPasswordRoute: typeof AuthResetPasswordRoute
  AuthIndexRoute: typeof AuthIndexRoute
}

const AuthRouteRouteChildren: AuthRouteRouteChildren = {
  AuthResetPasswordRoute: AuthResetPasswordRoute,
  AuthIndexRoute: AuthIndexRoute,
}

const AuthRouteRouteWithChildren = AuthRouteRoute._addFileChildren(
  AuthRouteRouteChildren,
)

interface UnauthRouteRouteChildren {
  UnauthConfirmRoute: typeof UnauthConfirmRoute
  UnauthForgottenPasswordRoute: typeof UnauthForgottenPasswordRoute
  UnauthLoginRoute: typeof UnauthLoginRoute
  UnauthOtpRoute: typeof UnauthOtpRoute
}

const UnauthRouteRouteChildren: UnauthRouteRouteChildren = {
  UnauthConfirmRoute: UnauthConfirmRoute,
  UnauthForgottenPasswordRoute: UnauthForgottenPasswordRoute,
  UnauthLoginRoute: UnauthLoginRoute,
  UnauthOtpRoute: UnauthOtpRoute,
}

const UnauthRouteRouteWithChildren = UnauthRouteRoute._addFileChildren(
  UnauthRouteRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof UnauthRouteRouteWithChildren
  '/reset-password': typeof AuthResetPasswordRoute
  '/confirm': typeof UnauthConfirmRoute
  '/forgotten-password': typeof UnauthForgottenPasswordRoute
  '/login': typeof UnauthLoginRoute
  '/otp': typeof UnauthOtpRoute
  '/': typeof AuthIndexRoute
}

export interface FileRoutesByTo {
  '': typeof UnauthRouteRouteWithChildren
  '/reset-password': typeof AuthResetPasswordRoute
  '/confirm': typeof UnauthConfirmRoute
  '/forgotten-password': typeof UnauthForgottenPasswordRoute
  '/login': typeof UnauthLoginRoute
  '/otp': typeof UnauthOtpRoute
  '/': typeof AuthIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteRouteWithChildren
  '/_unauth': typeof UnauthRouteRouteWithChildren
  '/_auth/reset-password': typeof AuthResetPasswordRoute
  '/_unauth/confirm': typeof UnauthConfirmRoute
  '/_unauth/forgotten-password': typeof UnauthForgottenPasswordRoute
  '/_unauth/login': typeof UnauthLoginRoute
  '/_unauth/otp': typeof UnauthOtpRoute
  '/_auth/': typeof AuthIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/reset-password'
    | '/confirm'
    | '/forgotten-password'
    | '/login'
    | '/otp'
    | '/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/reset-password'
    | '/confirm'
    | '/forgotten-password'
    | '/login'
    | '/otp'
    | '/'
  id:
    | '__root__'
    | '/_auth'
    | '/_unauth'
    | '/_auth/reset-password'
    | '/_unauth/confirm'
    | '/_unauth/forgotten-password'
    | '/_unauth/login'
    | '/_unauth/otp'
    | '/_auth/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRouteRoute: typeof AuthRouteRouteWithChildren
  UnauthRouteRoute: typeof UnauthRouteRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthRouteRoute: AuthRouteRouteWithChildren,
  UnauthRouteRoute: UnauthRouteRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/_unauth"
      ]
    },
    "/_auth": {
      "filePath": "_auth/route.tsx",
      "children": [
        "/_auth/reset-password",
        "/_auth/"
      ]
    },
    "/_unauth": {
      "filePath": "_unauth/route.tsx",
      "children": [
        "/_unauth/confirm",
        "/_unauth/forgotten-password",
        "/_unauth/login",
        "/_unauth/otp"
      ]
    },
    "/_auth/reset-password": {
      "filePath": "_auth/reset-password.tsx",
      "parent": "/_auth"
    },
    "/_unauth/confirm": {
      "filePath": "_unauth/confirm.tsx",
      "parent": "/_unauth"
    },
    "/_unauth/forgotten-password": {
      "filePath": "_unauth/forgotten-password.tsx",
      "parent": "/_unauth"
    },
    "/_unauth/login": {
      "filePath": "_unauth/login.tsx",
      "parent": "/_unauth"
    },
    "/_unauth/otp": {
      "filePath": "_unauth/otp.tsx",
      "parent": "/_unauth"
    },
    "/_auth/": {
      "filePath": "_auth/index.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
