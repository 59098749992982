'use client'

import React from 'react'
import {
  FieldError as RACFieldError,
  Group as RACGroup,
  Input as RACInput,
  Label as RACLabel,
  Text as RACText,
  composeRenderProps,
} from 'react-aria-components'

import type {
  FieldErrorProps as RACFieldErrorProps,
  GroupProps as RACGroupProps,
  InputProps as RACInputProps,
  LabelProps as RACLabelProps,
  TextProps as RACTextProps,
} from 'react-aria-components'

import { Button } from '../../buttons/button/button'
import { cn, composeTailwindRenderProps } from '../../utils'
import { fieldGroupStyles, inputStyles } from './field.styles'

export type LabelProps = RACLabelProps

export function Label(props: LabelProps) {
  return (
    <RACLabel
      {...props}
      className={cn(
        'text-subtle-foreground w-fit cursor-default text-xs font-medium',
        props.className,
      )}
    />
  )
}

export type DescriptionProps = RACTextProps

export function Description(props: DescriptionProps) {
  return (
    <RACText
      {...props}
      slot={'description'}
      className={cn('text-subtle-foreground text-xs', props.className)}
    />
  )
}

export type FieldErrorProps = RACFieldErrorProps

export function FieldError(props: FieldErrorProps) {
  return (
    <RACFieldError
      {...props}
      className={composeTailwindRenderProps(props.className, 'text-error-foreground text-xs')}
    />
  )
}

export type FieldGroupProps = RACGroupProps

export function FieldGroup(props: FieldGroupProps) {
  return (
    <RACGroup
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        fieldGroupStyles({ ...renderProps, className }),
      )}
    />
  )
}

export type InputProps = RACInputProps

export const Input = React.forwardRef<React.ElementRef<'input'>, InputProps>(
  (props, forwardedRef) => (
    <RACInput
      {...props}
      ref={forwardedRef}
      className={composeRenderProps(props.className, (className, renderProps) =>
        inputStyles({ ...renderProps, className }),
      )}
    />
  ),
)

Input.displayName = 'Input'

export function FieldTrigger({ children }: { children: React.ReactNode }) {
  return (
    <div className={'border-neutral-6 flex h-full w-9 items-center justify-center border-l'}>
      <Button
        variant={'ghost'}
        color={'neutral'}
        size={'sm'}
        className={
          'pressed:bg-neutral-3 focus-visible:bg-neutral-3 size-full rounded-none outline-none outline-0'
        }
      >
        {children}
      </Button>
    </div>
  )
}
