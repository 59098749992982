import { tv } from 'tailwind-variants'

import type { VariantProps } from 'tailwind-variants'

export const textStyles = tv({
  base: 'font-body',
  variants: {
    size: {
      inherit: 'text-inherit',
      '0': 'text-2xs leading-normal tracking-wide',
      '1': 'text-xs leading-normal tracking-normal',
      '2': 'text-sm leading-normal tracking-normal',
      '3': 'text-base leading-normal tracking-normal',
      '4': 'text-lg leading-snug tracking-tight',
      '5': 'text-xl leading-snug tracking-tight',
      '6': 'text-2xl leading-tight tracking-tight',
      '7': 'text-3xl leading-tight tracking-tight',
      '8': 'text-4xl leading-tight tracking-tight',
      '9': 'text-6xl leading-none tracking-tighter',
    },
    color: {
      accent: 'text-accent-11',
      current: 'text-current',
      default: 'text-foreground',
      error: 'text-error-foreground',
      info: 'text-info-11',
      muted: 'text-muted-foreground',
      negative: 'text-error-foreground',
      positive: 'text-success-foreground',
      subtle: 'text-subtle-foreground',
      warning: 'text-warning-foreground',
      yellow: 'text-yellow-9',
    },
    isDisabled: {
      true: 'text-disabled-foreground',
    },
    subtle: {
      true: 'text-subtle-foreground',
    },
    muted: {
      true: 'text-muted-foreground',
    },
    light: {
      true: 'font-light',
    },
    medium: {
      true: 'font-medium',
    },
    semiBold: {
      true: 'font-semibold',
    },
    accent: {
      true: 'text-accent-12',
    },
  },
  defaultVariants: {
    color: 'current',
    size: 'inherit',
  },
})

export type TextVariants = VariantProps<typeof textStyles>
