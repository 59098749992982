import { createFileRoute } from '@tanstack/react-router'

import { AppLayout } from '@/app/components/app-layout'
import { refreshAccessToken } from '@/features/auth/auth'

export const Route = createFileRoute('/_unauth')({
  beforeLoad: async ({ search }) => {
    if (!search.redirect_to) return

    const result = await refreshAccessToken()

    if (result.isOk()) {
      globalThis.location.replace(search.redirect_to)
    }
  },
  component: AppLayout,
})
