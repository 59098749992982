import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ErrorComponent, RouterProvider, createRouter } from '@tanstack/react-router'
import { Analytics } from '@vercel/analytics/react'
import isChromatic from 'chromatic'
import { MotionGlobalConfig } from 'framer-motion'
import { I18nProvider } from 'react-aria-components'

import type { NavigateOptions, ToOptions } from '@tanstack/react-router'

import { toast } from '@fysioscout/ui/status/toast'
import { Heading } from '@fysioscout/ui/typography/heading'
import { getErrorMessage } from '@fysioscout/utils/error'
import { ThemeProvider } from '@fysioscout/widgets/theme'

import { routeTree } from '@/app/gen/route-tree.gen'
import { env } from '@/config/env'

/**
 * When running in Chromatic, we want to skip animations to make the visual regression tests more
 * stable.
 *
 * This is a global setting that will affect all Framer Motion animations in the application.
 *
 * @see {@link https://www.chromatic.com/docs/animations/#javascript-animations}
 */
MotionGlobalConfig.skipAnimations = isChromatic() || env.VITE_DISABLE_ANIMATIONS

/** Project-wide React Query client */
const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error) => {
        return toast.error('Der skete en fejl.', {
          description: getErrorMessage(error),
        })
      },
    },
  },
})

/** Project-wide router instance */
const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },
  defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
  defaultNotFoundComponent: () => (
    <div className={'stack center min-h-screen'}>
      <Heading>404 - Not Found</Heading>
    </div>
  ),
  /**
   * Since we're using React Query, we don't want loader calls to ever be stale. This will ensure
   * that the loader is always called when the route is preloaded or visited
   */
  defaultPreloadStaleTime: 0,
})

/** Register the router instance for type safety */
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

declare module 'react-aria-components' {
  interface RouterConfig {
    href: ToOptions['to'] | (string & {})
    routerOptions: Omit<NavigateOptions, keyof ToOptions>
  }
}

export function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <I18nProvider locale={'da'}>
          <ThemeProvider>
            <RouterProvider router={router} />
          </ThemeProvider>
        </I18nProvider>
      </QueryClientProvider>

      <Analytics />
    </>
  )
}
