import type { FetchResponse } from 'openapi-fetch'

type MediaType = `${string}/${string}`

/**
 * Unwraps the data from an openapi-fetch fetch call, throwing an error if the response is an error
 * or if the data is undefined.
 *
 * @param promise - The API call to make.
 * @returns The unwrapped data from the response.
 * @throws If there is an error returned by the API call or if the data is unexpectedly undefined.
 */
export async function unwrap<
  T extends Record<string | number, unknown>,
  E,
  M extends MediaType = MediaType,
>(promise: Promise<FetchResponse<T, E, M>>): Promise<NonNullable<FetchResponse<T, E, M>['data']>> {
  const { data, error, response } = await promise

  if (error) throw new Error(error)

  if (data === undefined && response.status !== 204) {
    throw new Error('Unexpected undefined data for non-204 response')
  }

  return data as NonNullable<FetchResponse<T, E, M>['data']>
}
