import { createFileRoute } from '@tanstack/react-router'
import { useTitle } from 'ahooks'

import type { ForgottenPasswordDto } from '@/features/auth/types/models'

import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'

import { useResetPasswordForEmail } from '@/features/auth/api/use-reset-password-for-email'
import { ForgottenPasswordForm } from '@/features/auth/components/forgotten-password-form'

export const Route = createFileRoute('/_unauth/forgotten-password')({
  component: ForgottenPasswordView,
})

function ForgottenPasswordView() {
  useTitle('Glemt adgangskode')
  const resetPasswordMutation = useResetPasswordForEmail()
  const navigate = Route.useNavigate()

  const handleSubmit = (values: ForgottenPasswordDto) => {
    resetPasswordMutation.mutate(values, {
      onSuccess: () => void navigate({ to: '/confirm' }),
    })
  }

  return (
    <div data-testid={'forgotten-password-view'}>
      <Heading size={'5'}>Glemt adgangskode</Heading>

      <Text elementType={'p'} muted size={'2'} className={'mt-2'}>
        Indtast din e-mail, så sender vi dig et link til at nulstille din adgangskode.
      </Text>

      <ForgottenPasswordForm
        className={'mt-8'}
        submit={handleSubmit}
        isPending={resetPasswordMutation.isPending}
      />
    </div>
  )
}
