import { createFileRoute, redirect } from '@tanstack/react-router'
import { useTitle } from 'ahooks'
import { z } from 'zod'

import type { VerifyOtpBody } from '@/features/auth/api/use-verify-otp'

import { Heading } from '@fysioscout/ui/typography/heading'
import { Text } from '@fysioscout/ui/typography/text'

import { useVerifyOtp } from '@/features/auth/api/use-verify-otp'
import { VerifyOtpForm } from '@/features/auth/components/verify-otp-form'

const SearchSchema = z.object({
  email: z.string().email().catch(''),
})

export const Route = createFileRoute('/_unauth/otp')({
  component: OtpView,
  validateSearch: (search) => SearchSchema.parse(search),
  beforeLoad: ({ search }) => {
    if (!search.email) {
      throw redirect({
        to: '/login',
      })
    }
  },
})

function OtpView() {
  useTitle('Indtast kode')
  const search = Route.useSearch()
  const verifyOtpMutation = useVerifyOtp()

  const handleSubmit = ({ token }: Pick<VerifyOtpBody, 'token'>) => {
    verifyOtpMutation.mutate(
      { email: search.email, token },
      {
        onSuccess: () => {
          globalThis.location.replace(search.redirect_to ?? 'https://www.fysioscout.dk')
        },
      },
    )
  }

  return (
    <div data-testid={'signup-view'}>
      <Heading size={'5'}>Indtast kode</Heading>

      <Text elementType={'p'} size={'2'} muted className={'mt-2'}>
        Du har modtaget en e-mail med en kode. Indtast koden herunder. Hvis du ikke har modtaget en
        e-mail, så prøv igen.
      </Text>

      <VerifyOtpForm
        className={'mt-8'}
        submit={handleSubmit}
        isPending={verifyOtpMutation.isPending}
      />
    </div>
  )
}
