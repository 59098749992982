import React from 'react'
import { CheckIcon, CircleAlertIcon } from 'lucide-react'
import { useLocale } from 'react-aria-components'
import { map } from 'remeda'
import { match } from 'ts-pattern'

import { Button } from '@fysioscout/ui/buttons/button'
import { Form } from '@fysioscout/ui/forms/form'
import { TextField } from '@fysioscout/ui/forms/text-field'

interface Error {
  id: 'length' | 'uppercase' | 'numbers'
  message: string
  isValid: boolean
}

interface ResetPasswordFormProps {
  /** Optional CSS class name for the form. */
  className?: string

  /** Function to handle the password reset submission. */
  submit: (password: string) => void

  /** Indicates if the form submission is in progress. */
  isPending: boolean

  /** Indicates if the form is disabled. */
  isDisabled?: boolean
}

const MIN_LENGTH = 8
const MIN_UPPERCASE = 1
const MIN_NUMBERS = 1

export function ResetPasswordForm(props: ResetPasswordFormProps) {
  const { locale } = useLocale()

  const [errors, setErrors] = React.useState(getErrorMessages(locale))
  const [password, setPassword] = React.useState('')
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('')

  const confirmPasswordRef = React.useRef<HTMLInputElement>(null)

  /**
   * Validates the password based on the error messages.
   *
   * @param password - The password to validate.
   */
  const validatePassword = (password: string) => {
    const checkErrorValidity = (error: Error) =>
      match(error.id)
        .with('length', () => ({ ...error, isValid: hasValidLength(password) }))
        .with('uppercase', () => ({ ...error, isValid: hasRequiredUppercase(password) }))
        .with('numbers', () => ({ ...error, isValid: hasRequiredNumbers(password) }))
        .exhaustive()

    setErrors((errors) => map(errors, checkErrorValidity))
  }

  /**
   * Sets the password and validates it.
   *
   * @param password - The password to set and validate.
   */
  const setPasswordAndValidate = (password: string) => {
    setPassword(password)
    validatePassword(password)
  }

  /**
   * Handles the form submission.
   *
   * @param e - The form submission event.
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (password !== passwordConfirmation) {
      alert('Adgangskoderne er ikke ens.')
      confirmPasswordRef.current?.focus()
      return
    }

    props.submit(password)
  }

  /** HTML input pattern that validates one uppercase letter and one number */
  const pattern = '(?=.*[A-Z])(?=.*[0-9]).*'

  return (
    <Form className={props.className} onSubmit={handleSubmit}>
      <TextField
        label={'Ny adgangskode'}
        name={'password'}
        type={'password'}
        onChange={setPasswordAndValidate}
        inputMode={'text'}
        isRequired
        minLength={MIN_LENGTH}
        pattern={pattern}
        value={password}
      />

      <TextField
        ref={confirmPasswordRef}
        label={'Gentag adgangskode'}
        name={'confirm-password'}
        type={'password'}
        onChange={setPasswordConfirmation}
        inputMode={'text'}
        isRequired
        minLength={MIN_LENGTH}
        pattern={pattern}
        value={passwordConfirmation}
      />

      <Validations errors={errors} />

      <Button
        type={'submit'}
        className={'mt-2'}
        isPending={props.isPending}
        isDisabled={props.isDisabled}
      >
        Opdater adgangskode
      </Button>
    </Form>
  )
}

/**
 * Renders a list of validation rules.
 *
 * @param props - The properties passed to the Validations component.
 * @param props.errors - An array of Error objects representing the validation errors.
 * @returns - The rendered list of validation rules.
 */
function Validations(props: { errors: Error[] }) {
  return (
    <ul className={'stack gap-1.5 text-sm leading-normal'}>
      {props.errors.map((error) => (
        <ValidationRule key={error.id} isValid={error.isValid}>
          {error.message}
        </ValidationRule>
      ))}
    </ul>
  )
}

/**
 * Validates a rule and provides visual feedback based on the validity.
 *
 * @param props - The properties for ValidationRule.
 * @param props.isValid - A boolean indicating whether the rule is valid or not.
 * @param props.children - The content to be displayed within the validation rule.
 * @returns - The validation rule component.
 */
function ValidationRule({ children, isValid }: { isValid: boolean; children: React.ReactNode }) {
  return (
    <li className={'hstack items-center gap-2'}>
      {isValid ? (
        <CheckIcon className={'text-green-9 size-4 shrink-0'} aria-label={'check icon'} />
      ) : (
        <CircleAlertIcon
          className={'text-muted-foreground size-4 shrink-0'}
          aria-label={'alert icon'}
        />
      )}

      {children}
    </li>
  )
}

/**
 * Retrieves error messages based on the specified locale.
 *
 * @param locale - The locale to retrieve error messages for.
 * @returns - An array of error objects with message details.
 */
function getErrorMessages(locale: string): Error[] {
  const isDanish = locale === 'da'

  return [
    {
      id: 'length',
      message: isDanish
        ? `Adgangskoden skal være ${MIN_LENGTH.toString()} tegn eller mere.`
        : `Password must be ${MIN_LENGTH.toString()} character or more.`,
      isValid: false,
    },
    {
      id: 'uppercase',
      message: isDanish
        ? `Adgangskoden skal indeholde mindst ${MIN_UPPERCASE.toString()} stort bogstav.`
        : `Password must include at least ${MIN_UPPERCASE.toString()} upper case letter.`,
      isValid: false,
    },
    {
      id: 'numbers',
      message: isDanish
        ? `Adgangskoden skal indeholde mindst ${MIN_NUMBERS.toString()} tal.`
        : `Password must include at least ${MIN_NUMBERS.toString()} number.`,
      isValid: false,
    },
  ]
}

/**
 * Checks if a given password has a valid length (at least eight characters).
 *
 * @param password - The password to be checked.
 * @returns - Returns true if the password has a valid length, otherwise false.
 */
function hasValidLength(password: string) {
  return password.length >= MIN_LENGTH
}

/**
 * Checks if the given password has the required number of uppercase characters.
 *
 * @param password - The password to check.
 * @returns - True if the password has at least one uppercase characters, false otherwise.
 */
function hasRequiredUppercase(password: string) {
  return (password.match(/[A-Z]/g) ?? []).length >= MIN_UPPERCASE
}

/**
 * Checks if a password has the required number numbers.
 *
 * @param password - The password to check.
 * @returns - Returns true if the password has at least one number, otherwise false.
 */
function hasRequiredNumbers(password: string) {
  return (password.match(/\d/g) ?? []).length >= MIN_NUMBERS
}
